import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import * as styles from "./index.module.css";
import { Link } from "gatsby";



export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "crm-widok-kanban.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "crm-karta-zlecenia.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "crm-kanban-backlog.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Zarządzanie pracą zespołu marketingu w systemie CRM"
      keywords={["Zarządzanie rozproszonym zespołem marketingu, CRM, kanban"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Zarządzanie pracą zespołu marketingu w systemie CRM"
      metaTitle="Zarządzanie pracą zespołu marketingu w systemie CRM"
      metaDescription="Zarządzanie pracą zespołu marketingu w systemie CRM - czyli artykuł o tym, jak system CRM 
      może pomóc w zarządzaniu rozproszonym zespołem marketingowym"
    >
      <br />
      <br />
      <p>
        W zaistniałej sytuacji wiele przedsiębiorstw znalazło się w zupełnie
        nowym położeniu, które w zasadzie z dnia na dzień wymagało wprowadzenia
        szeregu zmian w modelu ich działalności. Dotyczy to również zagadnienia
        planowania oraz koordynacji pracy zespołów marketingowych, które
        zmuszone są do pracy bez bezpośredniego kontaktu. Wprowadzenie takich
        zmian nie jest prostym zadaniem, zwłaszcza dlatego, że aby utrzymać
        konkurencyjność w tych trudnych czasach, trzeba wdrażać je szybko.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/zGWsFKGU7qY"
        allowFullScreen
        ratio="16:9"
      />
      <p>
        Naprzeciw tym wyzwaniom wychodzą nowoczesne technologie informatyczne,
        takie jak system CRM. W jaki sposób to rozwiązanie może pomóc w
        organizacji pracy działu marketingu?
      </p>
      <br />
      <h2>Widok Kanban</h2>
      <p>
        <i>
          “Dla mnie, jako Marketing Managera, kluczowe znaczenie ma planowanie
          oraz monitorowanie realizacji naszych działań. Dlatego zdecydowaliśmy
          się na wykorzystanie widoków Kanban”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>

      <p>
        Jak można wykorzystywać takie widoki w praktyczny sposób w planowaniu
        pracy działu marketingu? Na początek przyjrzyjmy się przykładowej
        tablicy Kanban.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Zlecenia w widoku Kanban w systemie CRM"]}
      />
      <br />
      <p>
        Jak widzimy, nasze działania podzielone są na etapy, na które rozłożone
        są wszystkie zlecenia przydzielone na bieżący tydzień. Etapy te to
        “Nowe”, “Realizacja” oraz “Zakończone”. W pierwszej kolumnie znajdują
        się nowe zlecenia, które zostały przypisane do konkretnego członka
        zespołu i czekają na rozpoczęcie. W kolejnej znajdują się te zlecenia
        które są już podjęte i realizowane. Natomiast kolumna “Zakończone”
        zawiera zrealizowane już zlecenia, które przed ostatecznym zamknięciem
        wymagają jeszcze potwierdzenia.
      </p>

      <p>
        <i>
          “Wszystkie zlecenia z ostatniej kolumny szczegółowo omawiamy na
          cyklicznych spotkaniach, na które zapraszamy przedstawicieli naszych
          wewnętrznych klientów - w naszym przypadku działów sprzedaży”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>

      <p>
        Składające się na tablicę Kanban kafelki reprezentują pojedyncze
        zlecenia. Na każdym z nich znajdują się dodatkowe informacje, takie jak
        nazwa zlecenia, jego numer czy też przydzielony mu priorytet. Przydatna
        jest również wizualizacja w postaci pasku postępu, który informuje nas o
        tym, na jakim etapie realizacji jest dane zlecenie oraz ile godzin
        pozostało do jego zakończenia. Ta ostatnia informacja opiera się na
        estymatach podawanych przez pracowników. Jeżeli godziny już poświęcone
        na dane zlecenie w połączeniu z tymi przewidywanymi przekroczą pierwotne
        estymaty, system CRM zasygnalizuje to poprzez zmianę koloru tła w ikonce
        informacji procentowej.
      </p>

      <p>
        <i>
          “Z założenia samo spojrzenie na widok Kanban powinno nam dostarczyć
          komplet informacji na temat każdego zlecenia, bez wchodzenia w jego
          szczegóły”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>
      <br />
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz wesprzeć pracę Twojego zespołu marketingowego?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i zorganizować pracę marketingowców
            </Link>
          </div>
          </div>
    </section>
      <h2>Karta zlecenia</h2>
      <p>
        Po kliknięciu w nazwę zlecenia, system przeniesie nas do dedykowanej mu
        karty. Znajdują się tutaj informacje, których dobór zależy od potrzeb
        danego działu firmy.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Karta zlecenia w systemie CRM"]}
      />
      <br />
      <p>
        <i>
          “Uznaliśmy, że dla nas kluczowe są pola takie jak tagi, które już na
          samym widoku Kanban pozwalają doprecyzować temat zlecenia; priorytet,
          który sygnalizuje ważność danego zlecenia, jego opis oraz miejsce na
          podpięcie plików (na przykład projektów graficznych). Istotne dla nas
          są również czasy: planowany, wykorzystany i pozostały - uzupełniają
          się one automatycznie na podstawie rejestrowanych przez nas zdarzeń”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>

      <p>
        Warto również zwrócić uwagę na sekcję “Dokumenty” oraz “Wiadomości”. Ten
        pierwszy to obiekt w systemie, który pozwala na powiązanie danego
        zlecenia na przykład z innymi zleceniami lub szansami sprzedaży. Drugi
        zaś umożliwia nam zachowanie komunikacji w jednym miejscu poprzez
        wiązanie wiadomości ze zleceniem.
      </p>
      <br />
      <h2>Backlog</h2>
      <p>
        Istotne dla organizacji pracy działu marketingowego jest również to, w
        jaki sposób zlecenia w ogóle znalazły się na naszej tablicy?
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Backlog w widoku Kanban w CRM"]}
      />
      <br />
      <p>
        <i>
          “Moim dodatkowym wsparciem jest drugi widok Kanban, tak zwany Backlog.
          To miejsce, w którym każdego dnia wraz z pracownikami innych działów
          dodajemy nowe pomysły. Może to być nowa kampania, artykuł w prasie,
          ulotka czy projekt graficzny zmiany na stronie internetowej”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>

      <p>
        W pierwszej kolumnie dodawane są nowe koncepcje, natomiast we właściwym
        “Backlogu” lądują omówione i zaakceptowane zlecenia. Przesunięcia
        dokonywane na tym widoku (lub odrzucanie nietrafionych koncepcji) może
        leżeć w gestii managera lub być wynikiem narad na cyklicznych zebraniach
        z przedstawicielami działów sprzedaży lub jakichkolwiek innych
        zainteresowanych stron. Po ustaleniu priorytetów oraz kosztów, zadania
        przesuwane są z Backloga do realizacji.
      </p>
      <br />
      <h2>Co daje nam wykorzystanie Kanban?</h2>
      <p>
        Po pierwsze, upraszcza monitorowanie przepływu zleceń i eliminuje
        konieczność poświęcenia dodatkowego czasu na bezpośredni kontakt w
        sprawie każdego zlecenia.
      </p>

      <p>
        Po drugie, umożliwia sprawne planowanie pracy i przekazywanie zleceń -
        wystarczy wybrać z listy daną osobę i przypisać jej zlecenie, aby od
        razu było ono dla niej widoczne. Wystarczy rzut oka na widok Kanban, aby
        zapanować nad przepływem zleceń i określić czy nie mamy przestojów, czy
        terminy któregoś zlecenia nie są przekroczone i nie wymagają uwagi
        managera. Pomaga również określić, czy nie podjęliśmy zbyt mało lub zbyt
        wiele zleceń.
      </p>

      <p>
        <i>
          “Z perspektywy specjalisty do spraw marketingu uważam, że narzędzie to
          daje wysoki komfort pracy. Każdy z nas wie, czym powinien się zająć, w
          jakiej kolejności i wie czym zajmują się pozostałe osoby”
        </i>{" "}
        - Joanna Kujawska, Marketing Manager, Questy
      </p>

      <p>
        Zaimplementowane w systemie CRM widoki Kanban to narzędzie, które z
        powodzeniem można zastosować w wielu działach marketingowych w różnych
        branżach. To praktyczna pomoc szczególnie w przypadku zarządzaniu
        zespołem rozproszonym. To jednak nie wszelkie wsparcie, jakie może
        udzielić managerowi i pracownikom dobrej jakości system CRM - o
        kolejnych narzędziach (takich jak na przykład wykresy Gantta) napiszemy
        i opowiemy w materiałach video już niebawem. Zapraszamy!
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz wesprzeć pracę Twojego zespołu marketingowego?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i zorganizować pracę marketingowców
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Specrm;
